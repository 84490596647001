@import '@hiredigital/ui/variables';

.imageContainer {
  // display: flex;
  width: 100%;
  // height: auto;
  text-align: center;
}

.docContainer {
  width: 100%;
  height: 100%;
}

.image {
  // width: calc(100% - 40px);
  // margin: 20px auto;
  width: 100%;
  // max-width: $iframe-width;
  min-height: 100%;
  background-color: $true-black;
  // border-radius: 3px;
  object-fit: contain;
  cursor: pointer;
}

.content {
  padding: 20px;
  z-index: $z-base;
  box-shadow: 0 5px 20px 8px rgba(0, 0, 0, 0.15);
}

.keywords {
  margin-top: 20px;
}

.keywordsItem {
  display: inline-block;
  position: relative;
  margin: 0 5px 5px 0;
  padding: 5px 10px;
  border: 1px solid $gray-20;
  border-radius: $card-border-radius;
  color: $gray-80;
  font-size: 14px;
  font-weight: $fw-2;
}

.iframeContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  // background-color: $true-black;
  height: 100%;
  // max-height: calc(100vh - 200px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
    border-radius: 0;
  }

  .docContainer {
    height: 100%;
  }

  @media #{$sx-screen} {
    border-top: 1px solid $gray-20;
    border-bottom: 1px solid $gray-20;
    // display: block;
  }
}
