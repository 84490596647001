@import '@hiredigital/ui/variables';

.container {
  margin-bottom: 30px;
  position: relative;
  box-sizing: border-box;

  &.blue {
    background-color: $blue-120;
    color: $white;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: all 0.6s $cubic;
    z-index: $z-behind;
  }

  @include for-size(phone-only) {
    max-width: 100%;
  }
}

.header {
  margin-bottom: 16px;
  color: $neutral-800;
  font-size: $size-6;
  font-weight: $fw-2;
  line-height: $line-height-6;

  &.white {
    color: $white;
  }
}

.item {
  margin-bottom: 30px;
}

.description {
  font-size: $size-2;
  line-height: $line-height-2;
  margin-bottom: 16px;
}

.logo {
  width: 100px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.name {
  color: $neutral-600;
  font-size: $size-3;
  font-weight: $fw-2;
  line-height: $line-height-3;
}

.role {
  color: $gray-60;
  font-size: $size-2;
  line-height: $line-height-2;
  margin-bottom: 16px;
}

.divider {
  background-color: $gray-10;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;

  &:last-of-type {
    display: none;
  }
}
