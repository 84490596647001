@import '@hiredigital/ui/variables';

.container {
  display: flex;
}

.talentContainer {
  columns: 2;
  column-gap: 20px;

  @include for-size(tablet-landscape-down) {
    columns: 1;
  }
}
