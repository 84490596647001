/* Animations */

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

.container {
  :global(.no-objectfit) & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.image {
  :global(.no-objectfit) & {
    height: auto;
    width: 100%;
  }
}
