@import '../variables.scss';

.inputGroup {
  display: flex;
  width: 100%;

  .inputGroup {
    flex-basis: 50%;
    margin-left: 10px;
  }

  .inputContainer {
    flex: 50%;
  }

  .inputContainer ~ .inputContainer {
    margin-left: 10px;
  }

  @include for-size(tablet-portrait-down, 150px) {
    &.mobileOneColumn {
      flex-direction: column;

      .inputContainer ~ .inputContainer {
        margin-left: 0;
      }
    }
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;

  &.noMargin {
    margin-bottom: 0;
  }

  &.rateInputContainer {
    flex-direction: row;

    // input and first select
    > :nth-child(1) {
      flex-basis: 65%;
      margin-right: 10px;

      // first select
      > :nth-child(2) {
        border-left: 1px solid $gray-20;
        flex-basis: 100px !important;
      }
    }

    // second select
    > :nth-child(2) {
      flex-basis: 35%;
    }
  }
}
