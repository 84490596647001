@import '@hiredigital/ui/variables';

.list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // margin: 0 -30px -15px -30px;
  box-sizing: border-box;
  padding: 15px 10px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;

  // @include for-size(desktop-down) {
  //   padding: 15px 35px 15px 10px;
  // }
}

.item {
  flex-shrink: 0;

  height: 160px;
  width: 210px;
  object-fit: cover;
  border-radius: 3px;
  margin-right: 15px;
  box-shadow: $shadow-20;
}

.itemLink {
  margin: 0 7px;
  box-shadow: $shadow-20;
  border-radius: 3px;
  flex-shrink: 0;
  line-height: 0;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s $cubic;
  &:hover {
    box-shadow: $shadow-30;
  }
}

.itemImage {
  height: 100px;
  width: 150px;
  object-fit: cover;
}

.spacer {
  width: 15px;
  flex-shrink: 0;
}
