@import '../variables.scss';

.container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.scrollContainer {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.navItems {
  position: absolute;
  bottom: 0;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 50px;
  margin: 0;
  list-style: none;
  // background-color: $space-cadet;
}

.navListItem {
  height: 100%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navItem {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $white;
  opacity: 0.3;

  &.active {
    opacity: 1;
  }

  &::marker {
    opacity: 0;
  }
}

.item {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
