@import '@hiredigital/ui/variables';

.container {
  background-color: $blue-5;
  position: relative;
  z-index: 1;
  padding: 40px 20px;
  margin: auto;

  &.whiteTop {
    background-color: $white;
  }
}

.imageContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  overflow: hidden;
  z-index: $z-behind;
  top: 0;
  left: 0;
  background-color: $primary-100;

  .image {
    height: 100%;
    width: 100%;
  }

  .imageItem {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left center;
    position: relative;

    &.talent {
      object-position: top;
    }

    @include for-size(tablet-landscape-down) {
      &:after {
        position: absolute;
        height: 100%;
        width: 100%;
        content: '';
        backdrop-filter: blur(50px);
      }
    }
  }
}

.companyDescription {
  padding-right: 100px;
  @include for-size(phone-only) {
    padding-right: 0;
  }
}

.careerDescription {
  padding-right: 150px;
  font-size: $size-3;
  line-height: $line-height-3;
  @include for-size(phone-only) {
    padding-right: 0;
  }
}

.box {
  max-width: $content-width;
  padding: 50px;
  margin: auto;
  z-index: 0;
  position: relative;
  overflow: hidden;
  border-radius: $card-border-radius;
  box-sizing: border-box;

  @include for-size(desktop-down) {
    padding: 40px;
  }

  @include for-size(phone-only) {
    padding: 30px;
  }

  &.small {
    padding: 20px;

    @include for-size(phone-only) {
      .textContainer {
        padding: 10px;
      }
    }
  }
}

.buttons {
  display: flex;
  height: 100%;
  padding-top: 8px;

  @include for-size(phone-only) {
    flex-direction: column;
  }

  .btnApply {
    @include for-size(phone-only) {
      margin: 5px 0;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: $card-border-radius;
  min-height: 250px;
  z-index: $z-base;
  box-shadow: $shadow-40;

  &.bgTalent {
    background: url('/img/need-talent@2x.webp');
    background-size: cover;
  }

  &.bgPlant {
    background: url('/img/background-plant.webp');
    background-size: cover;
    background-position: right;
  }

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 0;

    &:after {
      position: absolute;
      height: 100%;
      width: 100%;
      content: '';
      backdrop-filter: blur(50px);
    }
  }
}

.textContainer {
  z-index: $z-base;
  padding: 30px;
  padding-right: 300px;

  .ctaHeadline {
    max-width: 600px;
    margin-bottom: 16px;
  }
  .ctaDescription {
    max-width: 600px;
    margin-bottom: 24px;
  }

  @include for-size(tablet-landscape-down) {
    padding: 30px;
  }

  @include for-size(phone-only) {
    padding: 30px;
  }
}

.talentCta {
  z-index: $z-background;
  // max-width: 1800px;
  margin: auto;
}

.careersLink {
  color: $blue-80;
  &:hover {
    text-decoration: underline;
  }
}
