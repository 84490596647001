@import '@hiredigital/ui/variables';

.container {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.item {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1 1 auto;

  @include for-size(phone-only) {
    padding: 10px 0;
    flex-basis: 50%;
  }
}

.number {
  color: $secondary-400;
  font-size: $size-11;
  margin: 20px;
  font-weight: $fw-2;

  .dark & {
    color: $secondary-400;
  }

  @include for-size(tablet-landscape-down) {
    font-size: $size-8;
  }

  @include for-size(phone-only) {
    font-size: $size-7;
    margin: 10px;
  }
}

.caption {
  min-height: 40px;
  margin: 10px 25px 0;
  color: $neutral-800;
  font-size: $size-5;
  font-weight: $fw-1;
  max-width: 200px;

  .dark & {
    color: $white;
  }

  @include for-size(phone-only) {
    margin: 10px 10px 0;
  }
}
