@import '@hiredigital/ui/variables';

.iframe {
  width: 100%;
  height: 100%;
  background-color: $black;
  flex-grow: 1;
  max-height: calc(100vh - 200px);
  display: block;
  width: 100%;
  height: 100%;
  border: none;

  @media #{$sm-screen} {
    height: 100vh !important;
  }

  @media #{$sx-screen} {
    height: 100% !important;
  }
}

.urlPreview {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  justify-content: center;
  background-color: $black;
}

.iframeError {
  margin: 10px;
  padding: 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  flex: 1 1 auto;
}
