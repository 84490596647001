@import '@hiredigital/ui/variables';

.list {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  justify-content: center;
  margin: 0 -15px;

  @include for-size(desktop-down) {
    margin: 0 0;
    padding: 0;
    width: 100%;
  }

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
  }
}

.description {
  @include for-size(phone-only) {
    display: none;
  }
}

.item {
  width: 365px;

  @include for-size(phone-only) {
    width: 100%;
    max-width: none;
  }
}

.itemSingle {
  flex-grow: 0;

  @include for-size(tablet-portrait-up) {
    width: 600px;
  }
}

.more {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 40px 0px;
}
