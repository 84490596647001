@import '@hiredigital/ui/variables';

.curveLine {
  display: inline;
  position: relative;
  word-break: keep-all;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -3px;
    height: 7px;
    width: calc(100% + 6px);
    border: 2px solid $teal;
    border-color: $teal transparent transparent transparent;
    border-radius: 50%;
  }
}
