@import '@hiredigital/ui/variables';

.sectionCta {
  padding: 7px 24px;
  margin-left: 0px;
  min-width: 200px;
  text-transform: capitalize;

  @include for-size(phone-only) {
    margin-top: 20px;
  }
}

.wrapper {
  position: relative;
  z-index: $z-base;

  // @include for-size(desktop-down) {
  //   margin: 20px;
  // }
}

.imageContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  overflow: hidden;
  background-color: #c2cce5;
  z-index: $z-behind;

  .image {
    height: 100%;
    width: 100%;
  }
  .imageItem {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right top;
  }
  @include for-size(phone-only) {
    .imageItem {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: left top;
    }
  }
}

.textContainer {
  padding: 40px;
  padding-right: 300px;

  .ctaHeadline {
    max-width: 600px;
    margin-bottom: 16px;
  }
  .ctaDescription {
    max-width: 600px;
    margin-bottom: 24px;
  }

  @include for-size(tablet-landscape-down) {
    padding-right: 210px;
  }

  @include for-size(phone-only) {
    padding: 30px;
    padding-right: 30px;
  }
}
