@import '@hiredigital/ui/variables';

.sectionContainer {
  position: relative;
  background-color: $primary-900;
  z-index: $z-background;
  overflow: hidden;

  min-height: calc(100vh - 378px);
  display: flex;
}

.imageContainer {
  position: absolute;
  z-index: $z-behind;
  line-height: 0;
  height: 100%;
  width: 100%;

  &:after {
    line-height: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: $primary-600;
    // padding-bottom: 64px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(-180deg, $primary-900 100%, hsla(0, 0%, 0%, 1) 100%),
      linear-gradient(40deg, $primary-900 20%, $neutral-500 50%);
    background-blend-mode: color, color;
    mix-blend-mode: multiply;
  }
}

.container {
  align-items: center;
  display: flex;
  padding: 200px 20px 160px;
  height: 100%;
  flex-grow: 1;
  max-width: $content-width + 40px;
  box-sizing: border-box;

  .text {
    .headline {
      margin-bottom: 18px;
    }

    .subheadline {
      font-weight: $fw-1;
      margin-bottom: 32px;
    }

    .button {
      @include for-size(phone-only) {
        margin: 5px 0;
      }
    }
  }
}

.image {
  height: 100%;
  width: 100%;
}

.imageItem {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
