@import '@hiredigital/ui/variables';

.sectionContainer {
  background: $primary-900;
  overflow: hidden;
  user-select: none;

  &.removeBackground {
    background: transparent;
  }

  .section {
    position: relative;
    display: flex;
    padding: 50px 0;
    height: 50px;
    user-select: none;
    overflow: hidden;
    will-change: opacity;
    animation: fadein 3s;
  }
}

.largeSuperSection {
  padding: 20px;
}

.largeLogoSection {
  position: relative;
  display: flex;
  justify-content: space-between;
  user-select: none;
  will-change: opacity;
  animation: fadein 2s;
  @include for-size(phone-only) {
    flex-wrap: wrap;
  }

  .largeImageContainer {
    @include for-size(tablet-landscape-down) {
      height: 75px;
      width: 150px;
    }

    @include for-size(phone-only) {
      height: 50px;
      width: 100px;
    }

    @include for-size(medium-phone-only, 60) {
      padding: 0 20px;
    }
  }
}

.superSection {
  position: relative;
  display: flex;
  padding: 20px 0;
  height: 50px;
  user-select: none;
  overflow: hidden;
  will-change: opacity;
  animation: fadein 3s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.logoSection {
  display: flex;

  position: absolute;
  white-space: nowrap;
  will-change: transform;

  .imageContainer {
    height: 50px;
    width: 100px;
    padding-right: 40px;
  }
}

.marquee {
  animation: marquee 40s linear infinite;

  .rightRow & {
    animation: marqueeRight 40s linear infinite;
  }
}

.image {
  height: 50px;
  width: 100px;
}
.largeImage {
  min-width: 200px;
  min-height: 100px;

  @include for-size(tablet-landscape-down) {
    min-height: 75px;
    min-width: 150px;
  }

  @include for-size(phone-only) {
    min-width: 100px;
    min-height: 50px;
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

@keyframes marqueeRight {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0%);
  }
}
