@import '../variables.scss';

.container {
  // width: 100%;
  background: $white;
  border-radius: $card-border-radius;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  border: 0;

  &.noBorder {
    border: 0;
    box-shadow: none;
  }
}

.panel {
  width: 100%;
  border-bottom: $inter-card-border;

  &:last-of-type {
    border-bottom: none;
  }
}

.panelHead {
  // width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  color: $black;
  cursor: pointer;
}

.panelBody {
  font-size: $size-3;
  line-height: $line-height-3;
  font-weight: $fw-1;
  // max-height: 250px;
  transition: all 0.3s $cubic;
  margin: 0 20px;
  padding: 0 35px;
  color: $neutral-500;
  padding-bottom: 20px;

  &.collapse {
    max-height: 0;
    overflow-y: hidden;
    padding: 0 !important;
  }
}

.title {
  color: $black;
  font-size: $size-4;
  // width: 100%;
  margin-right: 30px;

  @include for-size(phone-only) {
    font-size: $size-4;
  }

  &:hover,
  .panelHead:hover & {
    text-decoration: underline;
  }
}

.iconToggle {
  margin-right: 15px;
  min-width: 20px;
}
