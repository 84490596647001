@import '../variables.scss';

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;

  @media #{$sx-screen} {
    display: block;
    min-height: 400px;
  }
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  // width: 320px;
  width: 100%;
  margin: 20px auto;
  hyphens: manual;
}

.videoName {
  margin-bottom: 10px;
  font-weight: $fw-2;
  font-size: 14px;
  color: $gray-80;
  hyphens: manual;
}

.iframe {
  height: 100%;
  width: 100%;
  border: 0 none;
}

.name {
  font-size: 18px;
}

.urlButton {
  color: $black;
  text-decoration: underline;
  &:hover {
    color: $blue-100;
  }
}

.htmlPreviewContainer {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: $white;

  & > div {
    height: 100% !important;
    width: 100% !important;

    & > div {
      position: static !important; // fix the iframely issue that causes some of the content being clipped
    }
  }
}
