@import '../variables.scss';

.modalContainer {
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  cursor: default;
  // z-index: $z-dialog-mask;
  z-index: $z-image-viewer;
}

.modalPanel {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  z-index: $z-image-viewer;
  background: $black;
}

.content {
  padding: 0;
  max-height: none;
  background: $true-black;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  overflow: auto;
  hyphens: manual;
  word-break: break-word;

  @media #{$xs-screen},
  #{$short-screen} {
    max-height: none;
  }
  opacity: 0;

  img {
    box-shadow: $portfolio-item-shadow;
  }
  &.loaded {
    opacity: 1;
  }
  .image {
    max-width: calc(100% - 20px);
    height: auto;
    width: auto;
    cursor: zoom-in;
    margin: auto;
    padding: 0 10px 10px;
    box-sizing: border-box;
  }
}

.zoom0 {
  .image {
    cursor: zoom-in;
  }
}
.zoom1 {
  .image {
    cursor: zoom-out;
    transform: scale(1.5);
  }
}

.normal {
  &.zoom0 .image {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
  }

  &.zoom1 .image {
    max-width: none;
    max-height: none;
    transform: scale(1.5);
  }
}

.tall {
  &.zoom0 .image {
    max-width: calc(100% - 20px);
  }

  &.zoom1 .image {
    max-width: none;
  }
}

.wide {
  &.zoom0 .image {
    max-height: calc(100% - 20px);
  }

  &.zoom1 .image {
    max-height: none;
  }
}

.small {
  &.zoom0 .image {
    cursor: pointer;
    transform: scale(1);
  }

  &.zoom1 .image {
    cursor: pointer;
    transform: scale(1.25);
  }
}

.large {
  &.zoom0 .image {
    transform: scale(0.75);
  }

  &.zoom1 .image {
    transform: scale(1);
  }
}

.header {
  background: $black;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: $white;
  font-size: 18px;
}

.close {
  padding: 5px;
  cursor: pointer;
  line-height: 0;
  color: $white;
  margin-left: auto;
  @include icon-hover-dark;
}

.closeIcon {
  height: 20px;
  width: 20px;
  line-height: 0;
}
