@import '@hiredigital/ui/variables';

.meetingIframe {
  width: 100%;
  min-width: 750px;
  min-height: 650px;
  z-index: $z-base;
  margin-top: 20px;

  @include for-size(phone-only) {
    min-width: 100%;
  }
}