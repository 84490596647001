@import '../variables.scss';

.entered {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: $z-portfolio-viewer;

  @include for-size(tablet-portrait-down) {
    z-index: $z-portfolio-viewer-mobile;
  }
}

.mask {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-dialog-mask;
  // background: $black;
  background: rgba(0, 0, 0, 0.5);
}

.container {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
  }
  // @media #{$sm-screen} {
  //   // min-height: 100%;
  //   height: auto;
  // }
}

.side {
  position: relative;
  width: 380px;
  flex-shrink: 0;
  background: $gray-7;
  border-left: 1px solid $gray-10;
  z-index: $z-dialog;

  @include for-size(tablet-landscape-down) {
    width: 100%;
    border: 0;
  }
  // @media #{$sm-screen} {
  //   border: 0;
  //   width: 100%;
  // }
}

// .mobileMain {
//   display: none;
//   max-width: 600px;
//   z-index: $z-dialog;
//   border-top: 1px solid $gray-10;
//   @media #{$sm-screen} {
//     max-width: 100%;
//     display: block;
//   }
// }

.modalContentArea {
  height: 90vh;
  max-height: 880px;
  width: 100%;
}

.modal {
  position: relative;
  overflow: visible;
  cursor: default;
  width: 850px;
  position: relative;
  display: flex;
  flex-direction: column;
  // height: 100%;
  margin: auto;
  z-index: $z-dialog;

  @media #{$short-screen} {
    height: inherit;
  }
}

.panelIframe {
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);

  // fit to page
  @media #{$sm-screen} {
    max-height: 100%;
    max-width: 100%;
  }

  @media #{$short-screen} {
    max-height: 100%;
    max-width: 100%;
  }
}

.panelCase {
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);

  // fit to page
  @media #{$sm-screen} {
    max-height: 100%;
    max-width: 100%;
  }

  @media #{$short-screen} {
    max-height: 100%;
    max-width: 100%;
  }
}

.panelImage {
}

.main {
  max-width: 700px;
  z-index: $z-dialog;
  overflow: auto;
  display: flex;
  flex-direction: column;
  //position: absolute;
  background-color: $white;
  flex-grow: 1;
  //overflow: hidden;
  height: 100%;
  width: 100%;
  // margin-bottom: 50px;
  border-radius: 3px;
  position: relative;

  // @media #{$sm-screen} {
  //   margin-bottom: 0;
  //   display: none;
  // }

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $gray-2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $gray-20;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $gray-40;
  }

  @include for-size(tablet-landscape-down) {
    max-width: none;
    width: 100%;
  }
}

.mainIframe {
  .viewer {
    overflow: hidden;
  }
}

.mainCase {
}

.mainImage {
  min-height: 100%;
  //height: inherit;
  //margin-bottom: 50px;
  // full length

  // @media #{$xs-screen} {
  //   border-radius: 0;
  //   margin-bottom: 0;
  // }

  // @media #{$sm-screen} {
  //   border-radius: 0;
  //   margin-bottom: 0;
  // }
}

// .header {
//   position: sticky;
//   top: 0;
//   width: 100%;
// }

.sideHeader {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px 0;
  position: absolute;

  @include for-size(tablet-landscape-down) {
    display: none;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.sideBody {
  padding: 20px;

  @include for-size(tablet-landscape-down) {
    max-height: 350px;
    padding: 15px;
    padding-top: 8px;
    overflow: auto;
  }
}

.profileArea {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $gray-10;
  padding-bottom: 15px;
  margin-bottom: 15px;

  @include for-size(tablet-landscape-down) {
    flex-direction: row;
    // align-items: center;
    margin-bottom: 0;
    display: none;
  }
}

.smallScreenHeader {
  z-index: $z-dialog;
  width: 100%;
  display: none;
  position: relative;

  .actionClose {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .profileArea {
    background-color: $white;

    &:first-child {
      border-bottom: 1px solid $gray-10;
    }

    @include for-size(tablet-landscape-down) {
      display: flex;
      padding: 10px;
    }
  }

  @include for-size(tablet-landscape-down) {
    display: block;
  }
}

.section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &.row {
    flex-direction: row;
  }

  &:last-of-type {
    border-width: 0px;
  }

  &.line {
    padding-bottom: 15px;
    border-bottom: 1px solid $gray-10;
  }

  @include for-size(tablet-landscape-down) {
    margin-bottom: 0;

    &.line {
      border: 0;
      padding-bottom: 10px;
    }
  }
}

.spacer {
  flex-grow: 1;
  width: 100%;
}

.picture {
  height: 60px;
  width: 60px;
  flex-shrink: 0;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid $gray-7;
  margin-bottom: 10px;

  @include for-size(tablet-landscape-down) {
    height: 50px;
    width: 50px;
    margin-bottom: 0;
  }
}

.keywords {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin-right: -5px;
  margin-bottom: -5px;
}

.keyword {
  display: inline-block;
  position: relative;
  margin: 0 5px 5px 0;
  border-radius: 4px;
  color: $primary-900;
  background-color: $primary-00;
  font-size: $size-2;
  font-weight: $fw-2;
  white-space: nowrap;
  max-width: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  line-height: 18px;
  padding: 6px 7px;
}

.headerContent {
  width: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;

  @include for-size(tablet-landscape-down) {
    margin-left: 15px;
    justify-content: center;
  }
}

.link {
  min-height: 0;
  transition: 0.2s color $cubic;
  text-decoration: underline;
  text-decoration-color: transparent;
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: hidden;

  &.adminLink {
    margin-right: 20px;
    font-size: 14px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:hover {
    color: $black;
    text-decoration: underline;
  }
}

.subtitle {
  font-weight: 600;
  color: $black;
  font-size: $size-3;
  line-height: $line-height-3;
}

.title {
  font-weight: 600;
  color: $black;
  font-size: $size-5;
  line-height: $line-height-5;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  margin: 0;
  // text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
  // margin-bottom: 5px;

  // &:hover {
  //   color: $blue-80;
  //   text-decoration: underline;
  // }

  @media #{$sm-screen} {
    font-size: $size-4;
    margin: 3px 0;
    line-height: $size-4;
  }
}

.byline {
  font-size: $size-2;
  // line-height: $size-4;
  // color: $gray-60;
}

.caseTitle {
  font-weight: 600;
}

.viewer {
  flex-grow: 1;
  display: flex;
  overflow: auto;
  position: relative;
  background: $gray-80;
  height: auto;

  :global {
    .carousel {
      // height: 100%;
      margin: auto;
      position: initial;
    }
  }

  //   .carousel-inner {
  //     height: 100%;

  //     img {
  //       margin: auto;
  //     }
  //   }

  //   .carousel-item {
  //     height: 100%;

  //   }
  // }
}

.uploadContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  background-color: $gray-80;

  // img {
  //   max-width: 100%;
  //   max-height: 100%;
  //   width: auto;
  //   height: auto;
  //   margin: 0 auto;
  //   border-radius: 0;
  // }

  &.screenshot {
    overflow: auto;

    img {
      width: 100%;
      max-height: none;
    }
  }
}

.description {
  font-size: $size-2;
  line-height: $size-6;

  @include for-size(tablet-landscape-down) {
    margin-bottom: 5px !important;
  }
}

.headline {
  font-size: $size-2;
  line-height: $line-height-2;

  font-weight: $fw-2;

  line-height: $size-6;
  color: $secondary-600;
}

.content {
  padding: 10px;
}

.icon {
  color: $gray-60;
  // margin-left: 5px;
  line-height: 0;
  height: 20px;
  width: 20px;
  box-sizing: content-box;
  flex-shrink: 0;
  cursor: pointer;
  padding: 5px;
  @include icon-hover;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.iframeContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  background-color: $true-black;
  height: 100%;
  // max-height: calc(100vh - 200px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
    border-radius: 0;
  }

  .docContainer {
    height: 100%;
  }

  @media #{$sx-screen} {
    border-top: 1px solid $gray-20;
    border-bottom: 1px solid $gray-20;
    // display: block;
  }
}

.carouselImage {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-height: 100%;
}

.keywordsItem {
  display: inline-block;
  position: relative;
  margin: 0 5px 5px 0;
  padding: 0 10px;
  border: 1px solid $gray-20;
  border-radius: $card-border-radius;
  color: $gray-80;
  font-size: 14px;
  font-weight: $fw-2;
}

.loaderArea {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.casePreviewContainer {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.casePreview {
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  object-fit: cover;
  height: 45px;
  width: 45px;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid $blue-80;
  }
  &.active {
    border: 2px solid $blue-120;
  }
}

.actionClose {
  background: none;
  border: 0;
  padding: 0;
  margin-left: auto;
}

.navFooter {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  // height: 50px;
  border-top: 1px solid $gray-10;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
  background-color: $gray-7;

  @include for-size(tablet-landscape-down) {
    position: static;
  }
}

.nav {
  background: none;
  border: 0;
  padding: 0;
  outline: none;
  height: 100%;
  color: $blue-80;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.navIcon {
  height: 20px;
  width: 20px;
  color: $blue-80;
  padding: 5px;
  @include icon-hover;

  &.next {
    transform: rotate(-90deg);
    margin-left: 5px;
  }

  &.prev {
    transform: rotate(90deg);
    margin-right: 5px;
  }
}

.downloadLink {
  color: $blue-100;
  text-decoration: none;
  font-size: $size-2;
  line-height: $line-height-2;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px 0;

  &:hover {
    text-decoration: underline;
  }

  svg {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.linksContainer {
  @include for-size(tablet-landscape-down) {
    display: none;
  }
}

.navigator {
  position: absolute;
  top: calc(50% - (60px / 2));
  height: 70px;
  z-index: $z-dialog;
  color: $black;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  display: none;
  padding: 0;

  @include for-size(tablet-landscape-down) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: $gray-7;
  }

  &.previous {
    left: 8px;

    .iconPrevious {
      transform: rotate(90deg);
    }
  }

  .iconNavigator {
    width: 20px;
    height: auto;
  }

  &.next {
    right: 8px;

    .iconNext {
      transform: rotate(-90deg);
    }
  }
}

.navIndicatorContainer {
  @include for-size(tablet-landscape-down) {
    display: none;
  }
}

.actionMore {
  display: none;
  position: absolute;
  right: 0;
  top: 10px;
  // background-color: $gray-2;
  z-index: 10;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;

  @include for-size(tablet-landscape-down) {
    display: inline;
  }

  svg {
    height: 16px;
    width: 25px;
    color: $white;
    fill: $white;
  }
}
