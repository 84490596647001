@import '@hiredigital/ui/variables';

.container {
  position: relative;
  columns: 2;
  column-gap: 20px;

  @include for-size(tablet-landscape-down) {
    columns: 1;
  }

  @include for-size(tablet-portrait-down) {
    columns: 1;
  }
}

.singleContainer {
  position: relative;
}

.darkMode {
  .section {
    background: $primary-900;
    border: 0;
    padding-bottom: 20px;
  }

  .title {
    padding: 20px;
    color: $primary-100;
    border-bottom: 0;
  }

  .item {
    color: $primary-200;
    &:hover {
      color: $white;
    }
  }
}

.section {
  margin-bottom: 20px;
  box-sizing: border-box;
  background: $white;
  border-radius: $card-border-radius;
  position: relative;
  // box-shadow: $shadow-20;
  border: 1px solid $gray-20;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  // overflow: hidden;
}

.title {
  padding: 15px 20px;
  font-size: $size-6;
  font-weight: $fw-2;
  border-bottom: 1px solid $gray-10;
  grid-column: span 2;

  @include for-size(tablet-portrait-down) {
    font-size: $size-3;
  }
}

.list {
  columns: 2;
  column-gap: 10px;
}

.item {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 20px;
  color: $black;
  text-decoration: none;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  &:hover {
    text-decoration: underline;
    color: $primary-600;
  }

  @include for-size(tablet-portrait-down) {
    font-size: $size-2;
    padding: 10px 20px;
  }
}

.standardList {
  display: flex;
  flex-wrap: wrap;
  @include for-size(tablet-portrait-down) {
    &.hideOverflow {
      height: calc(38px * 10);
      overflow: hidden;
    }
  }
}

.standardItem {
  padding: 12.5px 10px;
  height: 17px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: $card-border-radius;
  background-color: $primary-00;
  text-decoration: none;
  transition: 0.2s all $cubic;

  &:hover {
    text-decoration: underline;
    color: $primary-600;
  }

  .darkMode & {
    color: $white;
    background-color: $primary-900;
    &:hover {
      background-color: $primary-800;
      color: $white;
    }
  }

  @include for-size(tablet-portrait-down) {
    padding: 10px;
    height: 18px;
  }
}
