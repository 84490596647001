@import '@hiredigital/ui/variables';

.container {
  &:hover {
    .controls {
      opacity: 1;
    }
  }

  :global {
    .packery-drop-placeholder {
      // outline: 3px dashed #444;
      // outline-offset: -6px;
      padding: 10px;
      box-sizing: border-box;
      background: $black;
      /* transition position changing */
      -webkit-transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      opacity: 0.05;
      border-radius: 5px;
      z-index: $z-background;
    }
  }
}

.header {
  height: 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-10;

  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  &:hover {
    background: $blue-5;
  }

  @media print {
    display: none;
  }
}

.viewIcon,
.openIcon {
  padding: 5px;
  height: 20px;
  width: 20px;
  border-right: 1px solid $gray-10;
  box-sizing: content-box;
  cursor: pointer;

  &:hover {
    color: $blue-80;
  }
}

.viewButton,
.openButton {
  text-transform: uppercase;
  border-width: 0;
  padding: 5px 10px;
  height: 19px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  border-right: 1px solid $gray-10;
  box-sizing: content-box;
  cursor: pointer;

  &:hover {
    color: $blue-80;
  }
}

.status {
  text-transform: uppercase;
  padding: 5px 10px;
  height: 19px;
  line-height: 19px;
  font-size: 12px;
  letter-spacing: 0.1px;
  color: $gray-60;
  box-sizing: content-box;
  white-space: nowrap;
}

//.isPrivate {
// position: absolute;
// top: 5px;
// right: 5px;
// background-color: $gray-80;
// color: white;
// text-transform: uppercase;
// padding: 5px 10px;
// font-weight: 600;
// line-height: 18px;
// font-size: 12px;
// letter-spacing: .1px;
// border-radius: 3px;
// box-sizing: content-box;
//}

.notify {
  text-transform: uppercase;
  padding: 2px 8px;
  font-weight: 600;
  line-height: 18px;
  font-size: 11px;
  letter-spacing: 0.1px;
  border-radius: 5px;
  margin: 4px 2px;
  color: white;

  &.isPrivate {
    background-color: $gray-80;
  }

  &.noContent {
    background-color: $red-50;
  }
}

.notifications {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: row;
  width: auto;

  @media print {
    display: none;
  }
}

.dragIcon {
  margin-left: auto;
  height: 20px;
  padding: 5px;
  box-sizing: content-box;

  &:hover {
    color: $blue-80;
  }
}

.thumbnail {
  position: static !important;
  user-select: none;
  width: 100%;
  height: 150px !important;
  object-fit: cover;
  border-radius: 2px 2px 0px 0px;
  &.pageScreenshot {
    object-position: center top;
  }
}

.thumbnailPlaceholder {
  @extend .thumbnail;
  background-color: $gray-5;
}

.details {
  padding: 20px;
  //border-top: 1px solid $gray-10;
}

.label {
  color: $gray-40;
  font-size: 12px;
  font-weight: $fw-3;
  line-height: 1;
  text-transform: uppercase;
  // letter-spacing: -.2px;
  line-height: 18px;
  margin-bottom: 3px;
}

.keywords {
  // Maps to skillset in marketing
  margin: 0px auto;
  margin-top: 10px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin-right: -5px;
  margin-bottom: -5px;
}

.keyword {
  opacity: 0.8;
  display: inline-block;
  position: relative;
  margin: 0 5px 5px 0;
  // border: 1px solid $blue-120;
  border-radius: 4px;
  color: $blue-150;
  background-color: $blue-5;
  font-size: 14px;
  // letter-spacing: -.1px;
  font-weight: $fw-2;
  white-space: nowrap;
  max-width: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  line-height: 18px;
  padding: 6px 7px;
}

.skill {
  display: inline-block;
  position: relative;
  margin: 0 5px 5px 0;
  padding: 5px 10px;
  border: 1px solid $gray-20;
  border-radius: $card-border-radius;
  color: $gray-80;
  font-size: 14px;
  font-weight: $fw-2;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: $gray-100;
  margin-bottom: 0;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.content {
  font-size: 14px;

  * {
    display: inline;
  }

  p {
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media print {
    display: none;
  }
}

.link {
  display: block;
  transition: 0.3s all $cubic;
  text-decoration: underline;
  text-decoration-color: transparent;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;

  &:hover {
    text-decoration-color: $black;
  }
}

.controls {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.2s $cubic;
  opacity: 0;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    min-width: 100px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
